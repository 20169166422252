/**
 * entzettelt
 * - copyright 2019-2024 Hill-Commerce GmbH
 * - developed by Manuel Pohl (m.pohl@hill-commerce.de)
 */

import DependencyWalker from '@/classes/Core/Share/helpers/DependencyWalker'
import ShareHelper      from '@/classes/Core/Share/helpers/ShareHelper'
import ShareCore        from "@/classes/Core/Share/core/ShareCore";

export default class Share
{

    constructor( core )
    {

        if( !Share.instance )
        {

            this.core = core
            this.logger = core.getLogger()
            this.store = core.getStore()
            this.f = core.f()
            this.eventManager = core.getEventManager()
            this.baseClassHelper = core.getBaseClassHelper()
            this.syncWorker = core.getSyncWorker()
            this.uuid = core.getUuid()
            this.cryptoCore = core.getCryptoCore()
            this.cryptoHelper = core.getCryptoHelper()
            this.queueWorker = core.getQueueWorker()
            this.database = core.getDatabase()
            this.client = core.getClient()
            this.ui = core.getUi()
            this.rights = core.r()
            this.sanitizers = core.getSanitizers()
            this.tsmp = core.getFriendlyTimestamp()
            this.timer = core.getCoreTimer()

            if( undefined === this.baseClassHelper )
            {
                this.eventManager.append( 'on-baseclasses-available', () =>
                {
                    this.baseClassHelper = core.getBaseClassHelper()
                } )
            }

            this.ready = false
            this.awaitingColleagueHeatup = false
            this.awaitingShareHelpers = false
            this.colleagues = []
            this.queueForType = []

            this.eventStates = {
                'colleague-refresh' : false,
                'share-helper-ready': false
            }

            this.init()

            this.dependencyWalker = new DependencyWalker( this )
            this.shareHelper = new ShareHelper( this )
            this.shareCore = new ShareCore( this )

            this.logSign = 'Core::Share [SHR]:'

            Share.instance = this


        }

        return Share.instance

    }

    /**
     * initialize share class
     */
    init()
    {
        if( !this.awaitingColleagueHeatup )
        {

            this.awaitingColleagueHeatup = true
            this.logger.clog( this.logSign + 'init', 'colleagues cache filled - setting up event handlers...' )
            this.setEventHandler()

        }
    }

    /**
     * destruct instance
     */
    destruct()
    {
        delete Share.instance
    }

    /**
     * refreshColleagues
     * - initialize the local colleagues cache
     */
    refreshColleagues()
    {

        if( 'filled' === this.baseClassHelper
                             .get( 'colleague' ).state )
        {

            let scopes     = [ 'cache', 'archive' ],
                colleagues = this.baseClassHelper
                                 .get( 'colleague' )
                                 .getCache(),
                list       = []

            for( let s in scopes )
            {
                /* eslint-disable-next-line no-unused-vars */
                for( const [ localId, colleague ] of colleagues[ scopes[ s ] ] )
                {
                    if( 2 === colleague.state )
                    {
                        list.push( colleague )
                    }
                }
            }

            this.colleagues = list
            this.eventManager.dispatch( 'on-share-ready', this )
            this.eventManager.dispatch( 'on-share-refresh' )
            this.ready = true
            this.eventManager.dispatchIndexed( 'on-share-refresh-component' )

        }

    }

    /**
     * setEventHandler
     */
    setEventHandler()
    {

        if( !this.eventStates[ 'colleague-refresh' ] )
        {

            this.eventStates[ 'colleague-refresh' ] = true
            this.eventManager.append( 'on-filled-state-colleague', () =>
            {
                if( true === this.store.getters.authorized )
                {
                    this.ready = false
                    this.refreshColleagues()
                }
                else
                {
                    this.eventManager.append( 'on-login-state-change', () =>
                    {
                        this.ready = false
                        this.refreshColleagues()
                    } )
                }
            } )

            this.eventManager.append( 'on-refresh-cache-colleague', () =>
            {
                this.ready = false
                this.refreshColleagues()
            } )

        }
    }

    /**
     * awaitShareHelperReady
     * @returns {Promise<unknown>}
     */
    awaitShareHelperReady()
    {
        return new Promise( resolve =>
        {

            if( this.shareHelper.ready )
            {
                return resolve()
            }
            else
            {
                this.awaitingShareHelpers = true
                if( !this.eventStates[ 'share-helper-ready' ] )
                {
                    this.eventManager.append( 'after-share-helper-ready', () =>
                    {

                        if( this.awaitingShareHelpers )
                        {
                            this.awaitingShareHelpers = false
                            return resolve()
                        }

                    } )
                }
            }

        } )
    }

    /**
     * isShared
     * @param object
     * @returns {boolean}
     */
    isShared( object )
    {
        return this.shareHelper.isShared( object )
    }

    /**
     * shareCount
     * @param colleague
     * @returns {Promise<unknown>}
     */
    shareCount( colleague )
    {
        return this.shareHelper.shareCount( colleague )
    }

    /**
     * sharedWith
     * @param object
     * @param uuid
     * @returns {boolean}
     */
    sharedWith( object, uuid )
    {

        if( this.f.isset( object._keys ) )
        {

            for( let k in object._keys )
            {
                if( object._keys[ k ].uuid === uuid )
                {
                    return true
                }
            }

            return false

        }

        if( 'list' === object.type
            && Array.isArray( object.lists ) )
        {
            for( let l in object.lists )
            {
                if( this.sharedWith( object.lists[ l ], uuid ) )
                {
                    return true
                }
            }
        }

        return false

    }

    /**
     * sharedWithArray
     * @param object
     * @param colleagues
     * @returns {boolean}
     */
    sharedWithArray( object, colleagues )
    {
        for( let c in colleagues )
        {
            if( this.sharedWith( object, colleagues[ c ].uuid ) )
            {

                return true

            }
        }

        return false

    }

    /**
     * shareList
     * @param object
     * @returns {*[]}
     */
    shareList( object )
    {
        return this.shareHelper.shareList( object )
    }

    /**
     * getOwnerColleague
     * @param object
     * @returns {*}
     */
    getOwnerColleague( object )
    {
        return this.shareHelper.getOwnerColleague( object )
    }

    /**
     * share
     */
    share( colleagueUuid, elms, shareDependencies, noSync )
    {

        return this.shareCore.share( colleagueUuid, elms, shareDependencies, noSync )

    }

    /**
     * unshare
     */
    unshare( colleagueUuid, elms, shareDependencies, noSync )
    {

        return this.shareCore.unshare( colleagueUuid, elms, shareDependencies, noSync )

    }

    /**
     * unshareAll
     * @param elms
     * @param shareDependencies
     */
    unshareAll( elms, shareDependencies )
    {
        return this.shareCore.unshareAll( elms, shareDependencies )
    }

    /**
     * unshareAllForColleague
     * @param shareWith
     */
    unshareAllForColleague( shareWith )
    {
        return this.shareCore.unshareAllForColleague( shareWith )
    }

}