import ShareSync from "@/classes/Core/Share/core/ShareSync";
/*eslint-disable*/
export default class ShareCore
{

    constructor( parent )
    {

        if( !ShareCore.instance )
        {

            this.parent = parent
            this.logSign = 'Core::Share [SHR]::ShareCore:'
            this.shareSync = new ShareSync( this.parent )

            ShareCore.instance = this


        }

        return ShareCore.instance

    }

    /**
     * _bundleLists
     * @param elementList
     * @returns {*[]}
     * @private
     */
    _bundleLists( elementList )
    {

        let newList  = [],
            localIds = []

        for( let e in elementList )
        {
            if( -1 === localIds.indexOf( elementList[ e ].localId ) )
            {

                if( elementList[ e ].type === 'list'
                    && this.parent.uuid.validate( elementList[ e ].localId ) )
                {

                    let container = this.parent.baseClassHelper
                                        .get( 'list' )
                                        .registry
                                        .cache
                                        .get( elementList[ e ].referenceKey )

                    if( undefined !== container )
                    {
                        for( let l in container.lists )
                        {
                            if( -1 === localIds.indexOf( container.lists[ l ].localId ) )
                            {
                                newList.push( container.lists[ l ] )
                                localIds.push( container.lists[ l ].localId )
                            }
                        }
                    }

                }
                else
                {
                    newList.push( elementList[ e ] )
                }

                localIds.push( elementList[ e ].localId )

            }
        }

        return newList

    }

    /**
     * _deepResolve
     * @param elementList
     * @param result
     * @param localIds
     * @returns {Promise<unknown>}
     * @private
     */
    _deepResolve( elementList, result, localIds, method )
    {
        return new Promise( resolve =>
        {

            result = result || []
            localIds = localIds || []

            if( 0 < elementList.length )
            {
                let elm = elementList.shift()
                if( -1 === localIds.indexOf( elm.localId )
                    && this.parent.uuid.validate( elm.localId ) )
                {
                    localIds.push( elm.localId )
                    this.parent.dependencyWalker.resolveDependencies( [ elm ], {}, method, false )
                        .then( items =>
                        {

                            for( let i in items )
                            {
                                let item = items[ i ]
                                if( -1 === localIds.indexOf( item.localId ) )
                                {
                                    result.push( item )
                                    localIds.push( item.localId )
                                }
                            }
                            return resolve( this._deepResolve( elementList, result, localIds, method ) )
                        } )
                }
                else
                {
                    return resolve( this._deepResolve( elementList, result, localIds, method ) )
                }
            }
            else
            {
                return resolve( result )
            }

        } )
    }

    /**
     * _cleanDoubles
     * @param elementList
     * @returns {*[]}
     * @private
     */
    _cleanDoubles( elementList )
    {
        let newList = []
        for( let e in elementList )
        {
            if( this.parent
                    .uuid
                    .validate( elementList[ e ].localId )
                && !newList.find( o => o.localId === elementList[ e ].localId ) )
            {
                newList.push( elementList[ e ] )
            }
        }
        return newList
    }

    /**
     * _prepareShareableObjectsList
     * @param shareWith
     * @param elementList
     * @param optional
     * @param method
     * @returns {{_count: number, create: *[], update: *[], delete: *[]}}
     * @private
     */
    _prepareShareableObjectsList( shareWith, elementList, optional, method )
    {

        let ids        = [],
            shareables = [],
            result     = {
                create: [],
                update: [],
                delete: [],
                _count: 0
            },
            objects    = {}

        for( let e in elementList )
        {

            if( undefined !== elementList[ e ].referenceKey
                && -1 === ids.indexOf( elementList[ e ].referenceKey ) )
            {

                ids.push( elementList[ e ].referenceKey )
                shareables.push( {
                    idReference: elementList[ e ].referenceKey,
                    objectType : elementList[ e ].type,
                    optional   : optional,
                    sharedWith : []
                } )
                objects[ elementList[ e ].referenceKey ] = elementList[ e ]

            }

            if( -1 === ids.indexOf( elementList[ e ].localId ) )
            {
                ids.push( elementList[ e ].localId )
                shareables.push( {
                    idReference: elementList[ e ].localId,
                    objectType : elementList[ e ].type,
                    optional   : optional,
                    sharedWith : []
                } )
                objects[ elementList[ e ].localId ] = elementList[ e ]
            }

        }

        for( let s in shareables )
        {

            let shareId = this.parent
                              .baseClassHelper
                              .get( 'share' ).registry.byReference
                              .get( shareables[ s ].idReference ),
                share   = this.parent
                              .baseClassHelper
                              .get( 'share' )
                              .getById( shareId )

            if( undefined !== shareId
                && undefined !== share )
            {

                let shareList = share.sharedWith
                for( let sw in shareWith )
                {
                    if( 'add' === method )
                    {
                        if( -1 === shareList.indexOf( shareWith[ sw ].uuid ) )
                        {
                            shareList.push( shareWith[ sw ].uuid )
                        }
                    }
                    if( 'remove' === method
                        && -1 < shareList.indexOf( shareWith[ sw ].uuid ) )
                    {
                        this.parent.f.removeFromArray( shareList, shareWith[ sw ].uuid )
                    }
                }

                if( shareList.length === 0 )
                {
                    result.delete.push( share )
                }
                else
                {
                    let newShare = this.parent.f.deref( share )
                    newShare.sharedWith = shareList
                    result.update.push( newShare )
                }
                result._count++


            }
            else
            {
                if( 'add' === method )
                {
                    if( undefined !== objects[ shareables[ s ].idReference ] )
                    {
                        for( let sw in shareWith )
                        {
                            shareables[ s ].sharedWith.push( shareWith[ sw ].uuid )
                        }
                        result.create.push( this.parent.f.deref( shareables[ s ] ) )
                        result._count++
                    }
                }
            }

        }


        ids = null
        shareables = null
        objects = null

        return result

    }


    /**
     * _prepareShares
     * @param shareWith
     * @param elementList
     * @param optional
     * @param method
     * @returns {Promise<unknown>}
     * @private
     */
    _prepareShares( shareWith, elementList, optional, method )
    {

        return new Promise( resolve =>
        {

            this.parent.baseClassHelper
                .get( 'share' )
                .cacheHeatup()
                .then( () =>
                {

                    return resolve( this._prepareShareableObjectsList( shareWith, elementList, optional, method ) )

                } )

        } )

    }

    /**
     * _writeShareables
     * @param shareables
     * @returns {Promise<unknown>}
     */
    _writeShareables( shareables )
    {

        return new Promise( resolve =>
        {

            let total     = shareables._count,
                step      = 0,
                baseClass = this.parent.baseClassHelper.get( 'share' )

            this.parent.ui.blockerText( ( total + ( total === 1 ? ' Update wird ' : ' Updates werden ' ) + 'geschrieben...' ) )
            this.parent.ui.updateProgress( total, step )

            for( let method in shareables )
            {
                if( '_count' !== method )
                {
                    for( let s in shareables[ method ] )
                    {

                        switch( method )
                        {
                            case 'create':
                                baseClass.create( shareables[ method ][ s ] )
                                break
                            case 'update':
                                if( Array.isArray( shareables[ method ][ s ].sharedWith )
                                    && 0 === shareables[ method ][ s ].sharedWith.length )
                                {
                                    baseClass.delete( shareables[ method ][ s ].localId, shareables[ method ][ s ].remoteId )
                                }
                                else
                                {
                                    baseClass.update(
                                        shareables[ method ][ s ],
                                        shareables[ method ][ s ].localId,
                                        shareables[ method ][ s ].remoteId,
                                        shareables[ method ][ s ].timestamp,
                                        shareables[ method ][ s ].localKey )
                                }
                                break
                            case 'delete':
                                baseClass.delete( shareables[ method ][ s ].localId, shareables[ method ][ s ].remoteId )
                                break
                        }

                    }

                    step++
                    this.parent.ui.updateProgress( total, step )

                }
            }

            return resolve()

        } )

    }

    /**
     * _getAllTypes
     * @param elementList
     * @returns {*[]}
     * @private
     */
    _getAllTypes( elementList )
    {
        let types = []
        for( let t in elementList )
        {
            let type = elementList[ t ].type
            if( -1 === types.indexOf( type ) )
            {
                types.push( type )
            }
        }
        return types
    }

    /**
     * _removeElementsToKeep
     * @param elementList
     * @param checkElms
     * @returns {Promise<unknown>}
     * @private
     */
    _removeElementsToKeep( elementList, checkElms )
    {
        return new Promise( resolve =>
        {

            let keepIds     = [],
                cleanedList = []

            for( let e in elementList )
            {
                for( let c in checkElms )
                {
                    if( -1 === keepIds.indexOf( elementList[ e ].localId )
                        && this.parent
                               .dependencyWalker
                               .isDependency( elementList[ e ], checkElms[ c ] ) )
                    {

                        keepIds.push( elementList[ e ].localId )

                    }
                }
            }

            for( let e in elementList )
            {
                if( -1 === keepIds.indexOf( elementList[ e ].localId ) )
                {
                    cleanedList.push( elementList[ e ] )
                }
            }

            return resolve( cleanedList )

        } )
    }

    /**
     * _checkRemainingBeforeUnshare
     * @param shareWith
     * @param elementList
     * @param optional
     * @param method
     * @returns {Promise<unknown>}
     * @private
     */
    _checkRemainingBeforeUnshare( shareWith, elementList, optional, method )
    {

        return new Promise( resolve =>
        {

            if( 'add' === method )
            {
                return resolve( elementList )
            }
            else
            {

                let allTypes  = this._getAllTypes( elementList ),
                    allShares = this.parent
                                    .baseClassHelper
                                    .get( 'share' )
                                    .getCache( 'cache' ),
                    checkElms = []

                /*eslint-disable-next-line*/
                for( const [ localId, share ] of allShares )
                {
                    if( -1 === allTypes.indexOf( share.objectType ) )
                    {
                        checkElms.push( share )
                    }
                }

                this._removeElementsToKeep( elementList, checkElms )
                    .then( list =>
                    {
                        return resolve( list )
                    } )

            }

        } )
    }

    _storeUnshares( elementList, shareWith )
    {
        return new Promise( resolve =>
        {

            let promises = []

            for( let e in elementList )
            {

                if( this.parent
                        .sharedWithArray( elementList[ e ], shareWith ) )
                {

                    promises.push( () =>
                    {
                        return new Promise( resolve =>
                        {

                            let unshare = {
                                idReference: elementList[ e ].localId,
                                objectType : elementList[ e ].type,
                                sharedWith : [],
                                timestamp  : Date.now(),
                                update     : Date.now()
                            }

                            for( let s in shareWith )
                            {
                                unshare.sharedWith.push( shareWith[ s ].uuid )
                            }

                            this.parent
                                .baseClassHelper
                                .get( 'unshare' )
                                .createWithPromise( unshare, undefined, undefined, undefined, undefined, undefined, true )
                                .then( () =>
                                {
                                    return resolve()
                                } )

                        } )
                    } )

                }

            }

            this.parent
                .f
                .promiseRunner( promises )
                .then( () =>
                {
                    return resolve()
                } )

        } )
    }

    _createUnshares( elementList, shareWith, method )
    {
        return new Promise( resolve =>
        {

            if( 'remove' === method )
            {
                this._storeUnshares( elementList, shareWith )
                    .then( () =>
                    {
                        return resolve()
                    } )
            }
            else
            {
                return resolve()
            }

        } )
    }

    /**
     * _resolveDependencies
     * @param elms
     * @param shareDependencies
     * @param method
     * @returns {Promise<unknown>}
     * @private
     */
    _resolveDependencies( elms, shareDependencies, method )
    {
        return new Promise( resolve =>
        {

            this.parent.dependencyWalker.resetIdentifiers()
            this.parent.dependencyWalker.resolveDependencies( elms, shareDependencies, method )
                .then( elementList =>
                {

                    let firstIter = this._bundleLists( this._cleanDoubles( elementList ) ),
                        promises  = [],
                        final     = []

                    for( let f in firstIter )
                    {
                        promises.push( () =>
                        {
                            return new Promise( resolve =>
                            {

                                final.push( firstIter[ f ] )
                                this.parent.dependencyWalker.resolveDependencies( [ firstIter[ f ] ], undefined, method )
                                    .then( todeep =>
                                    {

                                        this._deepResolve( todeep, undefined, undefined, method )
                                            .then( result =>
                                            {

                                                for( let r in result )
                                                {
                                                    if( !final.find( o => o.localId === result[ r ].localId ) )
                                                    {
                                                        final.push( result[ r ] )
                                                    }
                                                }

                                                return resolve()

                                            } )

                                    } )

                            } )
                        } )
                    }

                    this.parent
                        .f
                        .promiseRunner( promises )
                        .then( () =>
                        {

                            return resolve( this._bundleLists( this._cleanDoubles( final ) ) )

                        } )

                } )

        } )
    }

    /**
     * _processShareables
     * @param colleagueUuid
     * @param elms
     * @param shareDependencies
     * @param method
     * @returns {Promise<unknown>}
     * @private
     */
    _processShareables( colleagueUuid, elms, shareDependencies, method )
    {
        return new Promise( resolve =>
        {

            this.parent.ui.blockerText( 'Kolleg:innen werden geladen...' )
            this.parent.shareHelper.resolveShareWith( colleagueUuid )
                .then( ( shareWith ) =>
                {

                    this.parent.ui.blockerText( 'Abhängigkeiten werden gesucht...' )
                    this._resolveDependencies( elms, shareDependencies, method )
                        .then( elementList =>
                        {

                            console.log( '>>> UNSHARE', elementList )

                            let frozenElements = JSON.parse( JSON.stringify( elementList ) )
                            this.parent.ui.blockerText( 'Weitere Abhängigkeiten werden ermittelt...' )
                            this._deepResolve( elementList, undefined, undefined, method )
                                .then( res =>
                                {

                                    let result = this._cleanDoubles( [ ...frozenElements, ...res ] )
                                    this.parent.ui.blockerText( 'Schlüssel werden aktualisiert...' )
                                    /* no processing if method equals 'add' */

                                    this._checkRemainingBeforeUnshare( shareWith, result, shareDependencies, method )
                                        .then( elementList =>
                                        {

                                            /* no processing if method equals 'add' */
                                            this._createUnshares( elementList, shareWith, method )
                                                .then( () =>
                                                {

                                                    this._prepareShares( shareWith, elementList, shareDependencies, method )
                                                        .then( shareables =>
                                                        {

                                                            console.log( '>>> SHARE', shareables )

                                                            this._writeShareables( shareables )
                                                                .then( result =>
                                                                {

                                                                    return resolve( result )

                                                                } )

                                                        } )

                                                } )

                                        } )

                                } )

                        } )

                } )
        } )
    }

    /**
     * share
     * @param colleagueUuid
     * @param elms
     * @param shareDependencies
     * @returns {Promise<unknown>}
     */
    share( colleagueUuid, elms, shareDependencies, noSync )
    {

        return new Promise( resolve =>
        {

            this._processShareables( colleagueUuid, elms, shareDependencies, 'add' )
                .then( () =>
                {

                    if( !noSync || true )
                    {
                        this.shareSync.syncShares()
                            .then( () =>
                            {
                                return resolve()
                            } )
                    }
                    else
                    {
                        return resolve()
                    }

                } )

        } )

    }

    /**
     * unshare
     * @param colleagueUuid
     * @param elms
     * @param shareDependencies
     * @param noSync
     * @returns {Promise<unknown>}
     */
    unshare( colleagueUuid, elms, shareDependencies, noSync, ignoreShareables )
    {

        return new Promise( resolve =>
        {
            this._processShareables( colleagueUuid, elms, shareDependencies, 'remove', ignoreShareables )
                .then( () =>
                {

                    if( !noSync || true )
                    {
                        this.shareSync.syncUnshares()
                            .then( () =>
                            {
                                return resolve()
                            } )
                    }
                    else
                    {
                        return resolve()
                    }

                } )
        } )

    }

    /**
     * unshareAll
     * @param elementList
     */
    unshareAll( elms, shareDependencies )
    {

        return new Promise( resolve =>
        {

            let uuids = []
            for( let c in this.parent.colleagues )
            {
                uuids.push( this.parent.colleagues[ c ].uuid )
            }

            this.unshare( uuids, elms, shareDependencies, false, true )
                .then( () =>
                {
                    return resolve()
                } )

        } )

    }

    unshareAllForColleague( shareWith )
    {
        return new Promise( resolve =>
        {

            this.parent
                .baseClassHelper
                .getAllObjects()
                .then( elms =>
                {

                    for( let e in elms )
                    {
                        this.parent.eventManager.dispatch( 'long-block-sync-for-update', elms[ e ].localId )
                        if( Array.isArray( elms[ e ].lists ) )
                        {
                            for( let l in elms[ e ].lists )
                            {
                                this.parent.eventManager.dispatch( 'long-block-sync-for-update', elms[ e ].lists[ l ].localId )
                            }
                        }
                    }

                    this.unshare( [ shareWith ], elms, undefined, false, true )
                        .then( () =>
                        {

                            let colleague = this.parent.colleagues.find( o => o.uuid === shareWith )
                            if( false !== colleague )
                            {

                                let message = {
                                    method     : 'objects.removeAllKeysForColleague',
                                    idColleague: colleague.colleagueId
                                }

                                this.parent
                                    .client
                                    .request( message )
                                    .then( () =>
                                    {

                                        return resolve()

                                    } )
                                    .catch( e =>
                                    {
                                        console.error( 'E', e )
                                    } )

                            }

                        } )

                } )

        } )
    }

}